import React, {useEffect, useState} from 'react';
import {graphql, navigate} from 'gatsby';
import {PageProps} from '../../types';
import {CancelPageQuery} from './__generated__/CancelPageQuery';
import {PageWrapper, PageHeader, Heading, Spacer} from '../../styles/page';
import PageWidth from '../../components/PageWidth';
import {Layout} from '../../components/Layout';
import {getApiUrlFromData, getStudioPremiumPlanId} from '../../utils';
import {sendAnalyticsEvent, sendFeedbackEvent} from '../../utils/countly';
import {Cancellation, Reason} from '../../components/Cancellation';
import {useAuth} from '../../services/auth';
import {PaymentsApi} from '@focusrite-novation/ampify-api';
import {createAuthedApi, parseApiError} from '../../api';

type CancelPageProps = PageProps<CancelPageQuery>;

const CancelPage = (props: CancelPageProps) => {
  const {isSubscribedToPlan, isLoggedIn, getUserInfo} = useAuth();
  const [error, setError] = useState<string>('');
  const [planId, setPlanId] = useState<string>('');
  const [success, setSuccess] = useState<string>('');

  useEffect(() => {
    async function checkStatus() {
      const cancelRedirect = '/login?continue=/subscription/cancel';

      try {
        await getUserInfo(getApiUrlFromData(props.data));

        const premiumId = getStudioPremiumPlanId(props.data);
        if (!isLoggedIn) {
          navigate(cancelRedirect);
        } else if (!isSubscribedToPlan(premiumId)) {
          navigate('/subscription/plans');
        } else {
          setPlanId(premiumId);
        }
      } catch (err) {
        navigate(cancelRedirect);
      }
    }
    checkStatus();
  }, [props.data]);

  const handleCancellation = async (type: Reason, comment: string) => {
    setError('');
    setSuccess('');

    try {
      const api = createAuthedApi(PaymentsApi, getApiUrlFromData(props.data));

      await api.cancelUserSubscriptionPlan(planId);

      setSuccess(
        'Successfully cancelled your subscription. You can continue to use it until it expires.'
      );

      sendAnalyticsEvent('subscription-cancellation', {type});
      sendFeedbackEvent({comment});
    } catch (error) {
      const apiError = parseApiError(error);
      if (apiError?.statusCode === 404) {
        setSuccess(
          'Subscription already cancelled. You can continue to use it until it expires.'
        );
      } else {
        setError(apiError.message);
      }
    }
  };

  return (
    <Layout>
      <PageWrapper grey>
        <PageWidth narrow>
          <PageHeader>
            <Heading>Cancel Subscription</Heading>
          </PageHeader>
          <Spacer />
          <Spacer />
          {planId && (
            <Cancellation
              error={error}
              success={success}
              onSubmit={handleCancellation}
            />
          )}
        </PageWidth>
      </PageWrapper>
    </Layout>
  );
};

export const pageQuery = graphql`
  query CancelPageQuery {
    planData: allContentfulAmpifyWebPlans {
      edges {
        node {
          premiumId
        }
      }
    }
    site {
      siteMetadata {
        apiUrl
      }
    }
  }
`;

export default CancelPage;
