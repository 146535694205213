import styled from 'styled-components';
import {Paragraph} from '../../styles/page';
import {Button, ButtonVariant} from '../Button';

export const Notice = styled(Paragraph)`
  text-align: center;
`;

export const Option = styled.div`
  display: flex;
  align-items: center;
  line-height: 1rem;
  margin-left: 1rem;

  & > input[type='radio'] {
    margin-right: 1rem;
  }
`;

export const Message = styled.label`
  line-height: 1.5;
  transform: translateY(2px);
  cursor: pointer;
`;

export const Actions = styled.div`
  display: flex;
  justify-content: center;
`;

interface GoBackButtonProps {
  type: string;
  variant: ButtonVariant;
  onClick(): void;
}

export const GoBackButton = styled(Button)<GoBackButtonProps>`
  margin-left: 1rem;
`;
