import React, {useState, Fragment, useEffect, FunctionComponent} from 'react';
import {navigate} from 'gatsby';
import {DisplayMessage, DisplayMessageTypes} from '../DisplayMessage';
import {Button, ButtonVariant} from '../Button';
import {Box, Paragraph, Spacer, TextArea} from '../../styles/page';
import {
  Notice,
  Option,
  Message,
  Actions,
  GoBackButton,
} from './Cancellation.styles';

export enum Reason {
  NONE = 'none',
  TOO_EXPENSIVE = 'too_expensive',
  DO_NOT_USE_IT = 'do_not_use_it',
  JUST_TESTING = 'just_testing',
  SOUNDS = 'sounds',
  WORKFLOW = 'workflow',
  FEATURES = 'features',
  NOT_COMPATIBLE = 'not_compatible',
  OTHER = 'other',
}

interface CancellationProps {
  error: string;
  success: string;
  onSubmit(formReason: Reason, comments: string): void;
}

interface CancellationReason {
  type: Reason;
  value: string;
}

const cancellationReasons: CancellationReason[] = [
  {type: Reason.TOO_EXPENSIVE, value: "It's too expensive"},
  {type: Reason.DO_NOT_USE_IT, value: "I don't use it enough"},
  {type: Reason.JUST_TESTING, value: 'I was just testing it out'},
  {type: Reason.SOUNDS, value: "I couldn't find the sounds I wanted"},
  {type: Reason.WORKFLOW, value: "It's not suited to my workflow"},
  {
    type: Reason.FEATURES,
    value: "It doesn't have the features I need",
  },
  {
    type: Reason.NOT_COMPATIBLE,
    value: "It's not compatible with my hardware",
  },
  {type: Reason.OTHER, value: 'Other'},
];

export const Cancellation: FunctionComponent<CancellationProps> = (props) => {
  const [cancelling, setCancelling] = useState<boolean>(false);
  const [comments, setComments] = useState<string>('');
  const [formReason, setFormReason] = useState<Reason>(Reason.NONE);

  const handleCancellation = (e: React.FormEvent) => {
    e.preventDefault();
    setCancelling(true);

    const formattedComment = comments.length > 0 ? comments : 'No comment';

    props.onSubmit(formReason, `${formReason}: ${formattedComment}`);
  };

  useEffect(() => {
    if (props.success || props.error) {
      setCancelling(false);
    }
  }, [props.success, props.error]);

  return (
    <Box>
      <Fragment>
        {props.success && (
          <Fragment>
            <DisplayMessage
              type={DisplayMessageTypes.SUCCESS}
              data-test-id="subscription_cancellation_success"
            >
              <div>{props.success}</div>
            </DisplayMessage>
            <Actions>
              <Button
                variant={ButtonVariant.BLACK_OUTLINE}
                onClick={() => navigate('/my-account')}
              >
                Go back
              </Button>
            </Actions>
          </Fragment>
        )}
        {props.error && (
          <DisplayMessage data-test-id="cancellation_error">
            <div>{props.error}</div>
          </DisplayMessage>
        )}
        {!props.success && (
          <Fragment>
            <Notice>
              We're sorry that you wish to cancel your subscription.
              <br />
              We really value any feedback as it helps us to improve our
              service.
            </Notice>
            <Spacer />
            <form onSubmit={handleCancellation}>
              <Paragraph>
                <b>Why would you like to cancel your subscription?</b>
              </Paragraph>
              {cancellationReasons.map(({type, value}) => (
                <Option>
                  <input
                    id={type}
                    key={value}
                    type="radio"
                    name="reason"
                    onChange={(e: React.ChangeEvent) => setFormReason(type)}
                  />
                  <Message htmlFor={type}>{value}</Message>
                </Option>
              ))}
              <Spacer />
              <Spacer />
              <Paragraph>
                <b>
                  Please share any feedback you'd like us to take on board. The
                  more detail, the better.
                </b>
              </Paragraph>
              <TextArea
                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                  setComments(e.target.value)
                }
              />
              <Spacer />
              <Actions>
                <Button showSpinner={cancelling} onClick={handleCancellation}>
                  Cancel my subscription
                </Button>
                <GoBackButton
                  type="button"
                  variant={ButtonVariant.BLACK_OUTLINE}
                  onClick={() => navigate('/my-account')}
                >
                  Go back
                </GoBackButton>
              </Actions>
            </form>
          </Fragment>
        )}
      </Fragment>
    </Box>
  );
};
